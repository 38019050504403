@import '@/styles/common.scss';

/deep/.el-tabs__header {
  margin: 0 0 5px;
}

/deep/.el-dialog__body {
  overflow: auto;
  background-color: white;
}
/deep/.el-dialog__header {
  background-color: white;
}
/deep/.el-dialog__title {
  color: black;
}
